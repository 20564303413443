.independence-check-record-container {
    .btn {
        padding: 0.25rem 3rem;
    }

    .independence-check-record {
        .record-sub-details {
            margin-bottom: 1rem;
            .subtitle {
                color: #0071CE;
                margin: 0.5rem 0 0 0;
            }
        }
    }
}