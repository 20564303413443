$fwc_blue_primary: #00416A;
$fwc_blue_secondary: #0071CE;

.query-filings-chatbot {
    position: relative;
    height: 40rem;

    .bot-message {
        background-color: $fwc_blue_secondary;
        color: white;
    }

     .cs-message__content {
        background-color: $fwc_blue_primary;
        color: white;
     }

     .cs-button--send {
        
        color: $fwc_blue_primary
     }

     .clear-chat-btn {
        background-color: red;
        color: white;
        outline: none;
        border: none;
        padding: 0.5rem 1rem;
     }
}