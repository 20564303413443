.create-independence-check-records-container {
    .btn {
        padding: 0.25rem 3rem;
    }

    .title-section {
        color: #0071CE;

        .title {
            font-size: 1.25rem;
            font-weight: 500;
            display: inline;
            padding-right: 0.75%;
        }
    }

    .independence-check-relationship-header {
        margin-top: 1rem;
        margin-bottom: 1rem;

        .btn-secondary {
            padding: 0.25rem;
            max-width: 2.5rem;
            margin: 0 0.2rem 0 0.2rem;
        }
    }

    .variable-independenc-check-values {
        max-height: 30rem;
        overflow-y: auto;
        background-color: lightgrey;

        .independence-check-relationship {
            padding: 1rem;
            margin: 1 0rem;
            border: 7px solid lightgrey;
            background-color: white;

            .count-indicator {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .delete-relationship-button {
                display: flex;
                align-items: center;
                justify-content: center;

                .btn {
                    padding: 0.3rem 0.6rem;
                    margin: 0 0.2rem;
                }

                .btn-danger {
                    .fa-trash {
                        color: red;
                    }

                    &:hover {
                        background-color: red;

                        .fa-trash {
                            color: white;
                        }
                    }
                }
            }
        }
    }
}