.independence-check-data {
    width: 100%;
    background-color: white;
    padding: 1rem 1rem;
    margin: 0 auto;

    .btn {
        width: 100%;
    }

    .actions {
        display: inline-flex;
        justify-content: center;
        align-content: space-between;
        margin-top: 0.1rem;

        .btn {
            min-width: 9rem;

            &:first-child {
                margin-right: 0.5rem;
            }
        }

        i {
            font-size: 1rem;
            margin-right: 0.5rem;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .row-actions {
        display: inline-flex;
        justify-content: center;
        align-content: space-between;
        margin-top: 0.1rem;

        .btn {
            background: none;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
        }

        i {
            font-size: 1.5rem;
            margin-right: 0.5rem;

            &:hover {
                cursor: pointer;
            }
        }

        .fa-edit,
        .fa-eye {
            color: #00416A;
        }
    }
}